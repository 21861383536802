// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $dashboard-primary: mat.define-palette(mat.$indigo-palette);
// $dashboard-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $dashboard-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $dashboard-theme: mat.define-light-theme((
//   color: (
//     primary: $dashboard-primary,
//     accent: $dashboard-accent,
//     warn: $dashboard-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($dashboard-theme);


@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-ExtraLight.woff2") format("woff2");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Light.woff2") format("woff2");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Medium.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-SemiBold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-ExtraBold.woff2") format("woff2");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Manrope";
	src: url("./assets/fonts/Manrope-Variable.woff2") format("woff2");
	font-weight: 800;
	font-style: normal;
}

* {
	font-family: "Manrope";
}

html,
body {
	width: 100%;
	height: 100vh;
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	background-color: #EDEDED;
	font-size: 12px;

	.container {
		max-width: 1140px;
	}
}

.modal-open {
	overflow: hidden;
}

.spacer {
	flex-grow: 1;
}

.btn-primary-xl {
	@extend .btn-primary;
	border-radius: 5px !important;
	padding: 0.5rem 2em !important;
}

.btn-primary-lg {
	@extend .btn-primary;
	border-radius: 5px !important;
	padding: 0.1rem 1rem !important;
}

.btn-primary-sm {
	@extend .btn-primary;
	border-radius: 5px !important;
	padding: 0.1rem 0.25rem !important;
}

.btn-primary {
	width: fit-content;
	font-size: 1rem;
	border: 0.1rem solid;
}

.btn-primary:disabled {
	@apply border-gray-400 bg-gray-200 text-black;
}

.btn-purple {
	@extend .btn-primary;
	@apply border-purple-700 text-purple-700;
}

.btn-gray {
	@extend .btn-primary;
	@apply border-gray-700 text-gray-700;
}

.btn-primary:hover {
	@extend .btn-primary;
	@apply bg-purple-100;
}


.h-main {
	height: calc(100vh - 8rem)
}

.btn-secondary {
	@extend .btn-primary;
	@apply bg-red-400;
}

.form-container {
	@apply	flex justify-between py-2;
}

.form-heading {
	@apply font-bold text-lg px-2 pb-1;
}

.form-grid {
	@apply w-full flex flex-wrap gap-y-0.5 items-end;
}

.form-object-label {
	@apply	text-base font-bold text-gray-600 pb-4 px-2;
}

.form-group {
	@apply mx-2 flex flex-col gap-0.5 w-[15vw] justify-end;
}

.form-group-label {
	@apply text-base font-semibold text-gray-600 px-2;
}

.form-group-label-2 {
	@apply text-base font-semibold text-gray-400 px-2;
}

.form-input {
	@apply bg-white text-black text-base outline-1 border-gray-300 py-1 px-4 border-[1px] rounded-md w-full;
}

.form-input-d {
	@apply bg-white text-black text-base outline-1 border-gray-300 py-0 px-4 border-[1px] rounded-md w-full;
}

.enableSelect {
	@apply bg-white text-black text-base outline-1 border-gray-300 py-1 px-4 border-[1px] rounded-md w-full;
}

.form-input::placeholder {
	@apply text-sm px-2;
}

.form-input-date {
	@apply bg-gray-50 outline-none border-0 rounded-md flex justify-between text-base px-4 py-0;
}

.form-input:disabled,
.form-input:read-only {
	@apply outline-none py-2 bg-gray-100 border-0;
}

.form-input-d:disabled,
.form-input-d:read-only {
	@apply outline-none py-0 bg-gray-100 border-0;
}

.form-input-date:disabled {
	@apply outline-none p-0 bg-gray-100 border-0;
}

.form-textarea {
	@apply bg-white text-black text-sm outline-1 border-gray-400 py-1 px-4 border-2 rounded-md w-full;
}

//added

.file-input {
	@apply w-full h-full absolute left-0 opacity-0 cursor-pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.mat-select-placeholder {
	@apply text-sm px-2 font-[600];
}

input[type="date" i] {
	color: black;
}

.input-file {
	@apply outline-1 border-gray-300 px-4 border-[1px] rounded-md p-0;
}

.mat-option {
	@apply text-base font-[600];
}

.heading{
	@apply font-bold text-gray-700
}